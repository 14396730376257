*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
  --main-bg-color: rgb(255, 255, 255);
  --main-color: rgba(0, 0, 0, 0.84);
  --link-color: rgb(10, 133, 195);
  --paragraph-deemphasized: rgb(118, 118, 118);
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: rgb(31 32 40);
    --main-color: rgb(255, 255, 255);
    --link-color: rgb(54, 163, 255);
    --link-color-08: rgb(54, 163, 255, 0.8);
    --paragraph-deemphasized: rgb(169, 173, 193);
  }
}
html {
  box-sizing: border-box;
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

hr {
  height: 0;
}

abbr[title] {
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

ul {
  padding-left: 20px;
}

/*********/
a {
  color: var(--link-color);
}

a:hover,
a:focus {
  text-decoration: none;
}

html {
  background: var(--main-bg-color);
  color: var(--main-color);
}

.body-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 12px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile__image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.profile__description {
  text-align: center;
}

.social {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.social__item {
  display: flex;
  margin: 0 12px 12px 0;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
}
.social__icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  fill: var(--main-color);
}

.projects__description {
  margin: 0 0 12px 0;
}

.credits-list {
  list-style: circle;
}
.credits-list__item {
  font-size: 12px;
}
/*# sourceMappingURL=index.e8b673b8.css.map */
