@import './style.scss';

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  &__description {
    text-align: center;
  }
}

.social {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  &__item {
    display: flex;
    margin: 0 12px 12px 0;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    line-height: 1.2;
  }

  &__icon {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    fill: var(--main-color);
  }
}

.projects {
  &__description {
    margin: 0 0 12px 0;
  }
}

.credits-list {
  list-style: circle;
  &__item {
    font-size: 12px;
  }
}
