*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
  --main-bg-color: rgb(255, 255, 255);
  --main-color: rgba(0, 0, 0, 0.84);
  --link-color: rgb(10, 133, 195);
  --paragraph-deemphasized: rgb(118, 118, 118);
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: rgb(31 32 40);
    --main-color: rgb(255, 255, 255);
    --link-color: rgb(54, 163, 255);
    --link-color-08: rgb(54, 163, 255, 0.8);
    --paragraph-deemphasized: rgb(169, 173, 193);
  }
}

html {
  box-sizing: border-box;
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

hr {
  height: 0;
}

abbr[title] {
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

ul {
  padding-left: 20px;
}

/*********/

a {
  color: var(--link-color);
}

a:hover,
a:focus {
  text-decoration: none;
}

html {
  background: var(--main-bg-color);
  color: var(--main-color);
}

.body-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 12px;
}
